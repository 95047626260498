import { Vue, Component } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

interface QuestionResults {
  answerSummary: {
    average: number;
    count_of_answers: number;
    nonRatedValues: any;
    ratedValues: any;
    total_of_scores: number;
    values: any;
  };
  question: {
    correct_answer?: string;
    id: string;
    isQuiz: number;
    label: any;
    range: any;
    rate?: number;
    required: number;
    session_uuid: string|null;
    show_if: any;
    speaker_question: number;
    storage_type: string;
    type: string;
  };
  question_id: string;
  speaker: string|null;
}

@Component({
  name: 'GtrSurveyResultsView',
  computed: {
    ...mapState('surveys', ['surveyStats'])
  }
})
export default class GtrSurveyResultsView extends Vue {
  surveyStats: any;

  async mounted () {
    await this.fetchSurveyStats()
  }

  private async fetchSurveyStats () {
    try {
      await this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('surveys/fetchSurveyStats', {
        event_uuid: this.$route.params.event_uuid,
        session_uuid: this.$route.params.session_uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      await this.$store.dispatch('common/hideLoader')
    }
  }

  get sessionName (): string {
    if (this.$route.params.session_uuid === 'general' || this.$route.params.session_uuid === 'keynote') {
      return this.$route.params.session_uuid
    } else {
      return this.surveyStats?.session?.name ?? ''
    }
  }

  get isQuiz (): boolean {
    return this.surveyStats?.question_scores?.some((questionResults: QuestionResults) => questionResults.question.isQuiz) ?? false
  }

  get questionResults () {
    if (this.surveyStats?.question_scores) {
      return this.surveyStats.question_scores.map((questionResults: QuestionResults) => {
        const displayResults = {
          questionText: questionResults.question.label.en,
          type: questionResults.question.type === 'text' ? 'Text' : (questionResults.question.rate ? 'Rating' : 'Multiple Choice'),
          isQuiz: !!questionResults.question.isQuiz,
          numberCorrect: 0,
          numberIncorrect: 0,
          rows: [] as (string | number)[][]
        }

        const correctAnswer = questionResults.question.correct_answer
        const countsOfAnswers: { [key: string]: number } = questionResults.question.rate ? questionResults.answerSummary.ratedValues : questionResults.answerSummary.nonRatedValues
        const allAnswers: string[] = Object.keys(countsOfAnswers)
        const totalNumberOfAnswers = Object.values(countsOfAnswers).reduce((total, count) => total + count, 0) ?? 0
        const rows = displayResults.rows
        allAnswers.forEach(answer => {
          const count = countsOfAnswers[answer] ?? 0
          const percentage = parseFloat((100 * count / totalNumberOfAnswers).toFixed(2))
          if (answer === correctAnswer) {
            displayResults.numberCorrect = count
            rows.push(['correct', answer, count, percentage])
          } else {
            displayResults.numberIncorrect += count
            rows.push(['', answer, count, percentage])
          }
        })
        rows.sort((a, b) => {
          if (a[0] === 'correct') {
            return -1
          } else if (b[0] === 'correct') {
            return 1
          }
          return a[2] < b[2] ? 1 : -1
        }) // sort by count descending

        return displayResults
      })
    }
    return []
  }

  get averageScore (): number {
    return parseFloat((100 * this.questionResults.reduce((correctAnswers, currentQuestion) => correctAnswers + currentQuestion.numberCorrect, 0) / this.questionResults.length / this.surveyStats.filled_count).toFixed(2))
  }

  async downloadPdf () {
    const result = await this.$store.dispatch('surveys/downloadPdf', {
      event_uuid: this.$route.params.event_uuid,
      session_uuid: this.$route.params.session_uuid
    })
    window.open(result.data, '_blank')
  }
}
